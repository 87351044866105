import React from 'react';
import { graphql } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import { Container } from 'reactstrap';

import desktopHero44 from '../assets/images/carousel/desktop-banner-44.jpg';
import desktopHero28 from '../assets/images/carousel/desktop-banner-28.jpeg';
import desktopHero43 from '../assets/images/carousel/desktop-banner-43.jpg';

import mobileHero44 from '../assets/images/carousel/mobile-banner-44.jpg';
import mobileHero28 from '../assets/images/carousel/mobile-banner-28.jpeg';
import mobileHero43 from '../assets/images/carousel/mobile-banner-43.jpg';

import Layout from '../components/Layout';
import CarouselItem from '../components/CarouselItem';
import Hero from '../components/Hero';
import AppButtonsBanner from '../components/AppButtonsBanner';


function IndexPage({ data }) {
  const meta = [{
    name: 'description',
    content: 'With more than 39,000 locations in 102 countries, the SUBWAY® brand is the world\'s largest submarine sandwich franchise, and has become a leader in the international development of the quick service restaurant industry. The first SUBWAY® restaurant in Trinidad & Tobago was opened on November 19th, 1992.'
  },
  {
    name: 'keywords',
    content: 'Subway, Trinidad, Subway Trinidad, Tobago, Subway Tobago'
  }];

  return (
    <Layout meta={meta}>
      <Container fluid className="p-0">
        <Carousel
          autoPlay
          interval={5000}
          infiniteLoop
          emulateTouch
          showThumbs={false}
          dynamicHeight={false}
          showArrows={false}
          showStatus={false}
        >
          <CarouselItem src={{ sm: mobileHero43, md: desktopHero43 }} />
          <CarouselItem src={{ sm: mobileHero44, md: desktopHero44 }} />
          <CarouselItem src={{ sm: mobileHero28, md: desktopHero28 }} href="http://subwaytt.app.link/ijklm6cHOmb" />
        </Carousel>
      </Container>
      
      <AppButtonsBanner />
  
      <Hero
        backgrounds={{
          md: data.menuDesktop.size.fluid.src,
          sm: data.menuMobile.size.fluid.src
        }}
        heading="Menu"
        subheading="Make it your way."
        button={{ to: '/menu', caption: 'View our menu' }}
      />
  
      <Hero
        backgrounds={{
          md: data.careersDesktop.size.fluid.src,
          sm: data.careersMobile.size.fluid.src
        }}
        className="align-items-top align-items-md-center"
        backgroundPosition="center"
        heading="Careers"
        subheading="We're always looking for talented, motivated people to join our team."
        button={{ to: '/careers', caption: 'Learn more' }}
      />
    </Layout>
  );
};

export const data = graphql`
  {
    menuDesktop:file(relativePath: {eq: "hero/desktop-menu.jpg"}) {
      size:childImageSharp {
        fluid(maxWidth: 1200, toFormat: PNG, pngQuality: 90) {
          src
        }
      }
    }
    menuMobile:file(relativePath: {eq: "hero/mobile-menu.jpg"}) {
      size:childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG, pngQuality: 90) {
          src
        }
      }
    }
    careersDesktop:file(relativePath: {eq: "hero/desktop-careers.jpg"}) {
      size:childImageSharp {
        fluid(maxWidth: 1200, toFormat: PNG, pngQuality: 90) {
          src
        }
      }
    }
    careersMobile:file(relativePath: {eq: "hero/mobile-careers.jpg"}) {
      size:childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG, pngQuality: 90) {
          src
        }
      }
    }
  }
`;

export default IndexPage;
